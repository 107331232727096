import React, { useEffect, useState } from 'react';

export function DxBynAddPost() {
    const [postValue, setPost] = useState('');
    const [titleValue, setTitle] = useState('');
    const [error, setError] = useState(null);
    const publishPost = async () => {
      const url = 'https://api.dxby.dev/dxbyn/post.php';
      const data = new FormData();
      data.append('token', localStorage.getItem("token"));
      data.append("content", postValue)
      data.append("title", titleValue)

      try {
        const response = await fetch(url, {
          method: 'POST',
          body: data,
        });

        if (response.ok) {
          const result = await response.json();
          if (result.postid) {
            window.location.href = "https://dxby.dev/dxbyn/post/"+result.postid;
          }
          if (result.error) {
            setError(result.error);
          } else {
            
          }
        } else {
          console.error('Error - Status:', response.status);
          setError('Error sending post, try again! Status: '+response.status);
        }
      } catch (error) {
        console.error('Error while posting:', error);
      }
    }
    return (
      <div className='w-screen h-full flex flex-col'>
        <h1 className='custom-text-white-bold text-2xl ml-4 pt-2'>Write post</h1>
        <div className='w-screen h-screen flex flex-col outline-none border-none'>
          <div className='h-3/4 mt-3 mb-1 mr-3 ml-3 rounded-lg outline-none border-none bg-gray-950 flex flex-col justify-center items-center'>
            <textarea value={postValue} style={{ lineHeight: "19px" }} onChange={(e) => setPost(e.target.value)} name="content" autoCorrect='off' wrap='off' autoComplete='off' className='outline-none border-none bg-transparent text-white font-textbox cursor-text text-base w-full h-full resize-none p-3' rows="4" cols="50" placeholder="Write your post...BUT READ THE TERMS OF SERVICE (https://dxby.dev/dxbyn/post/1/)"></textarea>
          </div>
          <div className='flex flex-row flex-wrap justify-start items-center'>
            <div onClick={publishPost} className='bg-gray-800 flex border m-3 flex-row justify-start items-center pl-5 pr-5 pt-2 pb-2 fade-in select-none rounded-lg hover:-translate-y-1 hover:shadow-lg hover:bg-slate-600 cursor-pointer transition-all ease-in'>
                <h1 className='font text-white text-lg'>Publish</h1>
            </div>
            <input
                  className='w-72 h-10 pl-3 font text-white pr-2 mt-5 bg-slate-900 outline-none rounded-md focus:-translate-y-3 hover:-translate-y-3 -translate-y-2 transition-all ease-in'
                  autoComplete='off'
                  placeholder='Title'
                  type="text"
                  value={titleValue}
                  onChange={(e) => setTitle(e.target.value)}
                />
            <p className="font text-red-500 mb-5 ml-5 text-xl mt-5">{error}</p>
          </div>
        </div>
      </div>
    );
  }