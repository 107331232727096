import React, { useEffect, useState } from 'react';
import Particles from '../components/Particle';
import useMouseMoveEffect from '../js/useMousePopup';
import { HomeBTN, ContactBTN } from '../components/Buttons'

function NotFound() {
    const moveForce = 10;
    const rotateForce = 10;
  
    useMouseMoveEffect(moveForce, rotateForce);
    return (
        <>
        <Particles></Particles>
        <div className='w-full h-full overflow-hidden'>
            <div className='moving-zone min-h-screen select-none flex flex-col justify-center items-center text-center popup cursor-default'>
                <div className='popup-content flex flex-col items-center cursor-default'>
                    <h1 className='custom-text-white text-5xl custom-underline'>DxBy</h1>
                    <h1 className='custom-text-white font text-2xl'><error>404</error> - That's an error!</h1>
                    <h1 className='text-white font text-1xl'>The URL you were looking for was not found!</h1>
                </div>
                <HomeBTN></HomeBTN>

            </div>
        </div>
        </>
    );
  }
  
  export default NotFound;
  