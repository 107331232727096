import React, { useEffect, useState } from 'react';
import { FaHome } from "react-icons/fa";
import { MdLocalPostOffice } from "react-icons/md";

export function HomeBTN() {
    return(
        <div onClick={() => window.location.href = "/"} className='bg-gray-800 flex border flex-row justify-start items-center w-52 mt-2 fade-in select-none h-16 rounded-lg hover:-translate-y-1 hover:shadow-lg hover:bg-slate-600 cursor-pointer transition-all ease-in'>
            <FaHome className='ml-4 w-fit h-fit' size="40" color='white'/>
            <h1 className='custom-text-white ml-5 text-2xl'>Go back</h1>
        </div>
    )
}
export function ContactBTN() {
    return(
        <div onClick={() => window.location.href = "/contact"} className='bg-gray-800 border group fade-in-left w-72 mb-2 mr-2 fade-in group select-none h-16 rounded-lg flex flex-row items-center justify-start hover:-translate-y-1 hover:shadow-lg hover:bg-slate-600 cursor-pointer transition-all ease-in'>
        <MdLocalPostOffice className='ml-10' size="32" color='white'/>
        <h1 className='custom-text-white ml-5 text-2xl'>Contact me</h1>
      </div>
    )
}           