import React, { useEffect, useState } from 'react';
import "./style.css"
import Particles from '../components/Particle';
import { HomeBTN } from '../components/Buttons';
export function SteinRun() {
    const [counter, setCounter] = useState(0);
    const [highScore, setHighScore] = useState(
        localStorage.getItem('highScore')
          ? parseInt(localStorage.getItem('highScore'))
          : 0
      );
    var character = document.getElementById("character");
    var block = document.getElementById("block");

    const jump = () => {
        if(character.classList == "animate"){return}
        character.classList.add("animate");
        setTimeout(function(){
            character.classList.remove("animate");
        }, 300);
    }
    //TODO: Rangliste
    useEffect(() => {
        const checkDead = setInterval(() => {
            const character = document.getElementById("character");
            const block = document.getElementById("block");

            let characterTop = parseInt(window.getComputedStyle(character).getPropertyValue("top"));
            let blockLeft = parseInt(window.getComputedStyle(block).getPropertyValue("left"));

            if (blockLeft < 5 && blockLeft > -5 && characterTop >= 90) {
                block.style.animation = "none";
                setCounter(0);
                block.style.animation = "block 1s infinite linear";
            } else {
                setCounter((prevCounter) => prevCounter + 1);
                document.getElementById("scoreSpan").innerHTML = Math.floor(counter / 100);
            }
        }, 10);

        const handleKeyPress = (event) => {
            if (event.code === "Space") {
                jump();
            }
        };

        const handleTap = () => {
            jump();
        };
        const updateHighScore = () => {
            if (Math.floor(counter / 100) > highScore) {
              setHighScore(Math.floor(counter / 100));
              localStorage.setItem('highScore', Math.floor(counter / 100).toString());
            }
          };
      
        updateHighScore();

        window.addEventListener("keydown", handleKeyPress);
        window.addEventListener("touchstart", handleTap);
        return () => {
            clearInterval(checkDead);
            window.removeEventListener("keydown", handleKeyPress);
            window.removeEventListener("touchstart", handleTap);
        };
    }, [counter]);
    return(
        <>
        <Particles />
        <div className='flex flex-col w-screen h-screen justify-center items-center overflow-hidden' onClick={jump}>
            <h1 className='custom-text-white custom-underline text-4xl'>Stein-RUN</h1>
            <p className='font text-white mt-2 text-2xl'>
                Score: <span className='custom-text-holed-white text-3xl' id="scoreSpan"></span>
            </p>
            <p className='font text-white mt-2 text-2xl'>
                Highscore: <span className='custom-text-holed-white text-3xl'>{highScore}</span>
            </p>
            <div class="game">
                <img src='https://dxby.dev/player.png' id="character"></img>
                <img src='https://xvcf.dev/brian.svg' id="block"></img>
            </div>
            <HomeBTN/>
        </div>
        </>
    );
}

