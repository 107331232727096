import React, { useEffect, useState } from 'react';
import { DxBynLOGO } from './asciiLogo';

export function AdminCheck() {
    const isAdmin = localStorage.getItem('admin') === '1';
    const hide= () => {
        document.getElementById("notify").remove()
        document.getElementById("notify2").remove()
    }
    return (
        <><div id="notify" className={`absolute flex flex-col justify-start items-center center-abs rounded-lg w-96 h-fit p-3 shadow-red-500  shadow-lg drop-shadow-lg bg-slate-900 border-solid border-2 border-slate-950  z-50 ${isAdmin ? '' : 'hidden'}`}>
            <DxBynLOGO />
            <p className='text-white text-base w-96 text-center pr-2 pl-2 monspc'>We have noticed that you have changed your admin value in the localstorage,
                nice try but unfortunately you still can't do anything, feel free to look at this anyway :)</p>
            <button onClick={hide} className="text-white mt-3 pl-3 pr-3 pt-2 pb-2 bg-gray-800 hover:shadow-lg hover:bg-slate-600 cursor-pointer transition-all ease-in rounded-lg hover:-translate-y-1 select-none font w-3/4">Okay</button>
        </div><div id="notify2" className={`absolute flex flex-col justify-start items-center center-abs rounded-lg w-96 h-fit p-3 shadow-red-500  shadow-lg drop-shadow-lg bg-slate-900 border-solid border-2 border-slate-950  z-50 ${isAdmin ? 'hidden' : ''}`}>
                <DxBynLOGO />
                <p className='text-white text-base w-96 text-center pr-2 pl-2 monspc'>We have noticed that you are on an admin page, but this doesn't help you because you can't do anything here except look at reports :)</p>
                <button onClick={hide} className="text-white mt-3 pl-3 pr-3 pt-2 pb-2 bg-gray-800 hover:shadow-lg hover:bg-slate-600 cursor-pointer transition-all ease-in rounded-lg hover:-translate-y-1 select-none font w-3/4">Okay</button>
            </div></>
    );
}