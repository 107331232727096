import React, { useState, useEffect } from "react";
import '../index.scss';
import './index.css';
export function WhisperTOS() {
  return (
    <div className='w-screen h-screen flex flex-col items-center whisper-html'>
        <img className="-translate-y-10 size-72" src="https://dxby.dev/WHISPER.svg" />
        <h1 className="-translate-y-10 text-3xl text-black cfont-bold">Terms Of Service</h1>
        <div className="flex flex-col justify-center items-start">
            <h1 className="cfont-bold text-xl text-black">What is WHISPER?</h1>
            <p className="font text-black p-2 break-words max-w-screen-sm">
            A platform where you can send someone a text without them knowing it's your text.
            </p>
            <h1 className="cfont-bold text-xl text-black">Rules</h1>
            <p className="font text-black p-2 break-words max-w-screen-sm">
            Don't bully or harass someone, your responsible for that what you share.
            </p>
            <h1 className="cfont-bold text-xl text-black">What do we keep?</h1>
            <p className="font text-black p-2 pb-0 break-words max-w-screen-sm">
Of course we have a database with information. But what exactly do we
store?   
            </p>
            <p className="cfont-bold text-black pl-2 break-words max-w-screen-sm">
            We only store what you give us about you, your email, your username and your password is encrypted with md5 so that
nobody can read it!
            </p>
            <p className="font text-black p-2 pt-0 break-words max-w-screen-sm">
Your Ip address is not saved, but it is in the logs, it looks like this:
2024-01-20 22:23:22 91.2**.1*5.*24	200 GET /... HTTP/2.0
            </p>
        </div>
    </div>
  );}