import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import '../index.scss';
import './index.css';

export function WhisperWrite() {
  const { code } = useParams();
  const [isSent, setIsSent] = useState(false);
  const [username, setUsername] = useState("");  
  const [messageContent, setMessageContent] = useState("");
  const [error, setError] = useState(null);
  const [errorHTML, setErrorHTML] = useState(<div></div>)
  const [isError, setIsError] = useState(false);
  
  

  const handleWriteTextClick = () => {
    const message = document.getElementById('messageContent').value;
    setMessageContent(message)
    addMessage()
  };
  const redirect = () => {
    window.location.href="/whisper";
  }
  const redirect2 = () => {
      window.location.href="/whisper/sign/"+code;
    }
  const getUserByCode = async () => {
      const url = `https://api.dxby.dev/whisper/getUserByCode.php`;
      try {
        const data = new FormData();
        data.append("code", code)
        const response = await fetch(url, {method: "POST", body: data});
  
        if (response.ok) {
          const result = await response.json();
          console.log(result)
          if (result.error) {
            setError(result.error);
            setUsername("</>")
            console.error(result.error)
            setIsError(true)
            setErrorHTML(<div className='btn' onClick={redirect}>Back</div>);
          } else {
            setUsername(result.username)
            console.log(username)
            tokenCheck(result.username);
          }
        } else {
          console.error('Error - Status:', response.status);
          setError('Error fetching data');
        }
      } catch (error) {
        console.error('Error while fetching data:', error);
      }
    }
    const tokenCheck = (username) => {
      if (!localStorage.getItem("Wtoken")) {
          setIsError(true);
          console.log(username)
          setError("Please create an account or log in to write an anonyme message to "+username);
          setErrorHTML(<div className='btn mt-1' onClick={redirect2}>Login / Register</div>);
      }
    }
    const addMessage = async () => {
          const url = `https://api.dxby.dev/whisper/addMessage.php`;
          try {
            const data = new FormData();
            var token = localStorage.getItem("Wtoken");
            if (token) {
              data.append("token", token);
            }
            data.append("targetUserCode", code)
            data.append("messageContent", messageContent)
            const response = await fetch(url, {method: "POST", body: data});
      
            if (response.ok) {
              const result = await response.json();
              console.log(result)
              if (result.error) {
                setError(result.error);
                setIsError(true)
                setErrorHTML(<div className='btn' onClick={redirect}>Back</div>);
              } else {
                setIsSent(true);
                setTimeout(() => {
                  setIsSent(true);
                }, 1000); 
              }
            } else {
              console.error('Error - Status:', response.status);
              setError('Error fetching data');
              setErrorHTML(<div className='btn' onClick={redirect}>Back</div>);
            }
          } catch (error) {
            console.error('Error while fetching data:', error);
          }
        }
        useEffect(() => {
          getUserByCode();
        }, []);
        

  return (
    <div className='w-screen h-screen flex flex-col items-center whisper-html'>
          {isError ? (
            <div className='text-center w-full h-full mt-5 flex flex-col justify-center items-center'>
              <h1 className='text-4xl -mt-10 cfont-black'>OOPS</h1>
              {username && (<>
                {error}
                {errorHTML}
              </>
              )}
            </div>
          ) : (
            isSent ? (
              <div className='text-center mt-5'>
                Message sent to {username}!
                <div className='btn' onClick={redirect}>Back</div>
              </div>
            ) : (
              <>
                <img className='mt-10' src='https://dxby.dev/WHISPER.svg'></img>
                <div className='flex mt-10 flex-row gap-2'>
                  <h1 className='text-2xl'>What do you want to tell</h1>
                  <p className='cfont-bold text-2xl'>{username}</p>
                  <h1 className='text-2xl'>?</h1>
                </div>
                <h1 className="text-lg text-gray-700 font">{username} won't see who wrote this.</h1>
                <textarea
                  id='messageContent'
                  onChange={() => setMessageContent(document.getElementById('messageContent').value)}
                  className="resize-none mt-2 cursor-auto w-2/6 min-w-96 outline-none text-lg h-96 drop-shadow-lg textarea-hide-scrollbar border-black border-solid border-2 p-2 rounded-md bg-white"
                  placeholder='Write something...'
                />
                <div className='btn' onClick={handleWriteTextClick}>Send</div>
                <div className='btn' onClick={redirect}>Back</div>
                
              </>
            )
          )}
        </div>
      );
    }