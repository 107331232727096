import React from 'react';

export function DxBynLOGO() {
  const pastelRainbowColors = [
    '#FFC0CB',
    '#FFD700',
    '#98FB98',
    '#87CEEB', 
    '#FFA07A',
    '#DDA0DD',
    '#B0E0E6' 
  ];

  const asciiArt = `
___________________________________________________________________________________________________ DX _____________________
                                                                                                    DXB
        DXBYNDXB          DXBYN             DXBYN   DXBYNDXB            DXB                  BYN    DXBY         XBYN
        DXBYNDXBYN        DXBYN             DXBYN   DXBYNDXBYNDXBYN     DXBYN              DXBYN    DXBYN       DXBYN
        DXBYN    DXBYN     DXBYN           DXBYN    DXBYN      DXBYN     DXBYN            DXBYN     NDXBYN      DXBYN
        DXBYN     DXBYN     DXBYN         DXBYN     DXBYN      DXBYN      DXBYN          DXBYN      YNDXBYN     DXBYN
        DXBYN       DXBYN    DXBYN       DXBYN      DXBYN     DXBYN        DXBYN        DXBYN       DXBYNBYN    DXBYN
        DXBYN       DXBYN     DXBYN     DXBYN       DXBYN    DXBYN           DXBYN     DXBYN        DXBYNXBYN   DXBYN
        DXBYN       DXBYN      DXBYN   DXBYN        DXBYNDXBYN                 BYNDXBYNDXB          DXBYNDXBYN  DXBYN
        DXBYN       DXBYN       DXBYN DXBYN         DXBYNDXBYN                    DXBYN             DXBYN DXBYN DXBYN
        DXBYN       DXBYN        DXBYNDBYN          DXBYN    DXBYN                DXBYN             DXBYN  DXBYNDXBYN
        DXBYN       DXBYN         YNDXBYN           DXBYN      DXBYN              DXBYN             DXBYN   DXBYNDXBY
        DXBYN     DXBYN         DXBYN DXBYN         DXBYN      DXBYN              DXBYN             DXBYN    DXBYNDXB
        DXBYN    DXBYN         DXBYN   DXBYN        DXBYN    DXBYN                DXBYN             DXBYN     DXBYNDX
        DXBYNDXBYN            DXBYN     DXBYN       DXBYNDXBYN                    DXBYN             DXBYN      DXBYND
        DXBYNDXB               XBY       XBY        DXBYNDXB                       XBY               XBY        DXBYN
                                                                                    B                 B          XBYN
__________________________________________________________________________________________________________________ YN ______
                                                                                                                   YN
                                                                                                                    N
  `;

  const coloredAsciiArt = asciiArt
    .trim()
    .split('\n')
    .map((line, index) => `<span style="color:${pastelRainbowColors[index % pastelRainbowColors.length]}">${line}</span>`)
    .join('<br>');

  return (
    <div className='flex flex-col justify-center items-center tmargin-on-mobile'>
      <pre style={{ font: '5px/5px monospace', whiteSpace: 'pre-wrap' }} className='text-white' dangerouslySetInnerHTML={{ __html: coloredAsciiArt }} />
    </div>
  );
}
