import React, { useEffect, useState } from 'react';
import Particles from '../components/Particle';
import useMouseMoveEffect from '../js/useMousePopup';
import { FaCloudDownloadAlt } from "react-icons/fa";
function Photos() {
    const moveForce = 10;
    const rotateForce = 10;

    const imageUrls = [
        { url: 'https://cdn.dxby.dev/IMG_0001.png', text: '"STIEN" (Stein) in the UAE\'s desert' },
    ];

    useMouseMoveEffect(moveForce, rotateForce);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(false);
        }, 3000);

        return () => clearTimeout(timeout);
    }, []);

    const handleImageError = () => {
        setIsVisible(false);
    };
    return (
        <>
            <div className={`w-screen h-screen absolute pointer-events-none bg-slate-900 flex flex-row items-center justify-center z-50 fade-in-out ${isVisible ? '' : 'fade-out'}`}>
                <FaCloudDownloadAlt color='white' size={32}/>
                <h1 className='font ml-5 text-white text-3xl'> Loading...</h1>
            </div>
            <Particles></Particles>
            <div className='w-screen h-full flex flex-col items-center'>
                <div className='w-11/12 h-full flex mt-10 flex-col items-center overflow-hidden'>
                    <h1 className='custom-text-white text-4xl custom-underline'>DxBy's Photo Gallery</h1>
                    <div className='w-full mt-5 flex flex-row justify-center flex-wrap'>
                        {imageUrls.map((item, index) => (
                            <div key={index} className='w-60 h-fit select-none photo overflow-hidden justify-center m-3 pb-7 text-center cursor-pointer'>
                                <div className='flex flex-col overflow-hidden w-60 h-80 items-center cursor-pointer'>
                                    <img
                                        onClick={() => window.open(item.url)}
                                        src={item.url}
                                        className='w-full rounded-md border-none outline-none cursor-pointer h-full object-cover'
                                        alt={`Image ${index}`}
                                        onError={handleImageError}
                                    />
                                </div>
                                <h1 className="text-xl mt-0.5 text-white font custom-text-white-bold">{item.text}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Photos;
