import React from 'react';
import { useState,useEffect } from 'react';
import { DxBynLOGO } from './components/asciiLogo';
import { MdOutlineMenu,MdPushPin } from "react-icons/md";
export function DxBynHome() {
  const [searchedPost, setSearchedPost] = useState('');
  const [typedText, setTypedText] = useState("");
  const words = [
    "How to learn coding",
    "Terms of service",
    "Unusual name meaning",
    "Poppins font",
    "Who is DxBy?",
    "Free D-Bucks Generator",
    "Python programming basics",
    "Website terms explained",
    "Uncommon name meanings",
    "Understanding fonts",
    "DxBy background"
  ];
  let isReversed = false;

  useEffect(() => {
    let wordIndex = 0;
    let letterIndex = 0;

    const intervalId = setInterval(() => {
      const currentWord = words[wordIndex];
      const currentLetter = currentWord && currentWord[letterIndex];

      if (isReversed) {
        setTypedText((prevTypedText) => prevTypedText.slice(0, -1));
      } else {
        setTypedText((prevTypedText) => prevTypedText + (currentLetter || ""));
      }

      letterIndex += isReversed ? -1 : 1;

      if (isReversed && letterIndex === 0) {
        setTimeout(() => {
          isReversed = false;
          wordIndex = (wordIndex + 1) % words.length;
        }, currentWord && currentWord.length === letterIndex ? 2000 : 0);
      } else if (!isReversed && letterIndex === (currentWord && currentWord.length)) {
        isReversed = true;
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsData, setPostsData] = useState([]);

  const postsPerPage = 25;
  const filteredPosts = postsData
    .filter(post => post.title.toLowerCase().includes(searchedPost.toLowerCase()))
  const totalPages = Math.ceil(filteredPosts.length / postsPerPage);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const posts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  const getAllPosts = async () => {
    const url = 'https://api.dxby.dev/dxbyn/post.php';
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.error) {

      }else {
        setPostsData(data.posts);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getAllPosts();
  }, []);
  return (
    <div className='flex flex-col mt-10 justify-center items-center'>
      <DxBynLOGO/>
      <div className="search-container -z-10 flex flex-row justify-start items-start">
        <span className="custom-text-white text-4xl text-search">Search: <span className="custom-text-holed-white typed-text">{typedText}</span>
          <span className="typed-cursor typed-cursor--blink" aria-hidden="true"></span>
        </span>
      </div>
      <input
        className='w-72 h-10 pl-3 font text-white pr-2 mt-5 bg-slate-900 outline-none rounded-md focus:-translate-y-1 hover:-translate-y-1 transition-all ease-in'
        autoComplete='off'
        placeholder='Search post'
        type="text"
        value={searchedPost}
        onChange={(e) => setSearchedPost(e.target.value)}
      />
      <div className='flex w-screen flex-col items-center justify-center'>
      {posts.length > 0 ? (
        <table className='blurbox w-11/12 max-w-3xl mobile-w-full mt-5 text-white font'>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Creator</th>
              <th>Views</th>
              <th className='hide-on-mobile'>Created</th>
            </tr>
          </thead>
          <tbody>
          {posts
            .sort((a, b) => (a.pinned === "1" ? -1 : 1))
            .map((post, index) => (
              <tr key={index} style={{ marginBottom: index === 0 && post.pinned === "1" ? '2px' : '0', background: post.pinned === "1" ? 'rgb(255 75 75 / 50%)' : 'transparent' }}>
                <td>{post.id}</td>
                <td className={`hover:text-blue-500 cursor-pointer hover:underline flex flex-row ${post.pinned === "1" ? 'custom-text-white text-xl rgb-text' : ''} `} onClick={() => window.location.href = `/dxbyn/post/${post.id}`}>
                  {post.pinned === "1" && <MdPushPin className='mr-2 hide-on-mobile' size={20} color='white'/>}
                  {post.title}
                </td>
                <td
                className={`hover:underline hover:text-blue-500 cursor-pointer transition-all ease-in
                  ${post.isDev === "1" ? 'dev-class' : ''}
                  ${post.isVip === "1" ? 'vip-class rgb-text' : ''}
                  ${post.isadmin === "1" ? 'custom-text-white admin' : ''}
                  `}
                  onClick={() => window.location.href = `/dxbyn/users/${post.creatorid}`}
                >
                  {post.creatorname}
                </td>
                <td>{post.views}</td>
                <td className='hide-on-mobile'>{post.created}</td>
              </tr>
            ))}

          </tbody>
        </table>
        ) : (
          <p className="custom-text-white text-xl mt-5 custom-underline">No posts found</p>
        )}
        <div className="mt-4 flex flex-row justify-center items-center font text-white">
            <button className="pagination-btn font" onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous Page
            </button>
            <span className="mx-2 fade-in">{`Page ${currentPage} of ${totalPages}`}</span>
            <button className="pagination-btn font" onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next Page
            </button>
          </div>
      </div>

    </div>
  );
}
