import React, { useEffect } from 'react';

function Footer() {

    useEffect(() => {
    const handleScroll = () => {
      const eTR = document.getElementById('remove-on-scroll');

      if (eTR) {
        eTR.style.transition = 'opacity 0.3s linear';
        eTR.style.opacity = '0';

        setTimeout(() => {
          eTR.style.display = 'none';
        }, 500);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return(
    <>
        <div className='flex flex-row bottom-0 items-center justify-center z-50'>
            <div className='flex flex-col items-center ml-5 mr-5 pl-8 pr-8 pt-5 pb-5 mt-2 mb-5 justify-center font text-white text-base gap-1'>
                <h1 className='text-lg select-none custom-underline'>Me</h1>
                <a href='mailto:stein@dxby.dev'>stein@dxby.dev (Email)</a>
            </div>
            <div className='flex flex-col items-center ml-5 mr-5 pl-8 cursor-pointer pr-8 pt-5 pb-5 mt-2 mb-5 justify-center font text-center text-white text-base gap-1'>
                <h1 className='text-lg select-none custom-underline'>Projects</h1>
                <a onClick={() => window.open('https://dxby.dev/dxbyn')}>DxByn (Forum / Text Sharing)</a>
                <a onClick={() => window.open('https://dxby.dev/steinrun')}>Stein Run (Game)</a>
            </div>
        </div>
        <p className='font text-white opacity-50 text-sm z-50'>© 2024 - All rights reserved | Made by Stein</p>
        <br></br>
        <div className='fixed fade-in-bottom bottom-0 w-full h-48 z-40 bgradient flex flex-col justify-center items-center' id="remove-on-scroll">
            <p className='font text-white opacity-50 text-sm z-50'>Scroll to reveal the invisible</p>
        </div>
    </>
    )
}
export default Footer;