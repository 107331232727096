import React, { useState } from 'react';
import '../index.scss';
import Particles from '../components/Particle';
import useMouseMoveEffect from '../js/useMousePopup';
export function Signer() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [Result, setResult] = useState(null);

    const handleLogin = async () => {
        const url = 'https://api.dxby.dev/dxbyn/login.php';
        const data = new FormData();
        data.append('name', username);
        data.append('password', password);

        const xhr = new XMLHttpRequest();

        xhr.addEventListener('readystatechange', function () {
            if (this.readyState === this.DONE) {
                try {
                    const result = JSON.parse(this.responseText);
                    if (result && result.token) {
                        //* TOKEN FOUND, SAVE IT as cookie
                        localStorage.setItem("token", result.token);
                        localStorage.setItem("admin", result.admin);
                        localStorage.setItem("id", result.id);
                        setResult("")
                        window.location.href = "/dxbyn"
                    } else {
                        setResult(result.error)
                    }
                } catch (error) {
                    console.error('Error while parsing JSON:', error);
                }
            }
        });

        xhr.open('POST', url);
        xhr.setRequestHeader('User-Agent', 'DXBYN');

        xhr.send(data);
    };
    const moveForce = 10;
    const rotateForce = 10;

    useMouseMoveEffect(moveForce, rotateForce);

    const handleRegister = async () => {
        const url = 'https://api.dxby.dev/dxbyn/register.php';
        const data = new FormData();
        data.append('name', username);
        data.append('password', password);

        const xhr = new XMLHttpRequest();

        xhr.addEventListener('readystatechange', function () {
            if (this.readyState === this.DONE) {
                try {
                    const result = JSON.parse(this.responseText);
                    if (result && result.token) {
                        //* TOKEN FOUND, SAVE IT as cookie
                        localStorage.setItem("token", result.token);
                        localStorage.setItem("admin", "0");
                        localStorage.setItem("id", result.id);
                        setResult("")
                        window.location.reload()
                    } else {
                        setResult(result.error)
                    }

                } catch (error) {
                    console.error('Error while parsing JSON:', error);
                }
            }
        });

        xhr.open('POST', url);
        xhr.setRequestHeader('User-Agent', 'DXBYN');

        xhr.send(data);
    };

    return (
        <>
        <div className='w-screen h-1/2 mt-24 flex flex-col justify-center items-center moving-zone'>
            <div className='-translate-y-14 flex flex-col justify-center items-center'>
                <h1 className='custom-text-white -translate-y-2 text-5xl custom-underline'>DxByn</h1>
                <h1 className='font text-white text-2xl'>A service to paste and share texts!</h1>
            </div>
            <div className='w-96 h-52 popup'>
                <div className='popup-content blurbox hover:backdrop-blur-md hover:-translate-y-1 pt-2 pb-4 rounded-lg hover:shadow-lg cursor-pointer transition-all ease-in fade-in group flex flex-col justify-center items-center'>
                    <h1 className='custom-text-holed-white text-3xl mt-2 mb-3'>Register / Login</h1>
                    
                    <p className='font text-red-500 text-xl pl-4 pr-4 text-center'>{Result !== null ? JSON.stringify(Result, null, 2).replace('"', "").replace('"', "").replace("'", "") : ''}</p>

                    <input className='w-3/4 h-10 pl-3 font text-white pr-2 mt-3 bg-slate-900 outline-none rounded-md focus:-translate-y-1 hover:-translate-y-1 transition-all ease-in' autoComplete='off' placeholder='Username' type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    <input className='w-3/4 h-10 pl-3 font text-white pr-2 mt-3 bg-slate-900 outline-none rounded-md focus:-translate-y-1 hover:-translate-y-1 transition-all ease-in' autoComplete='off' placeholder='Password' type="password" value={password} onChange={(e) => setPassword(e.target.value)} />

                    <div className='flex flex-row gap-3 mt-4'>
                        <div onClick={handleLogin} className='popup-content blurbox hover:backdrop-blur-md w-11/12 fade-in select-none h-10 rounded-lg flex flex-row items-center justify-center hover:-translate-y-1 hover:shadow-lg cursor-pointer transition-all ease-in'>
                            <h1 className='custom-text-white text-2xl pl-5 pr-5'>Login</h1>
                        </div>
                        <div onClick={handleRegister} className='popup-content blurbox hover:backdrop-blur-md w-11/12 fade-in select-none h-10 rounded-lg flex flex-row items-center justify-center hover:-translate-y-1 hover:shadow-lg cursor-pointer transition-all ease-in'>
                            <h1 className='custom-text-white text-2xl pl-5 pr-5'>Register</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
