import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useMouseMoveEffect from '../js/useMousePopup';

import "./index.css";

export function DxBynUsers() {
  let { id } = useParams();
  id = /^[0-9]+$/.test(id) ? parseInt(id, 10) : ''; 
  const isCertainUser = /^[0-9]+$/.test(id);
  const [searchedUsername, setSearchedUsername] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 15;
  const [usersData, setUsersData] = useState([]);

  const filteredUsers = usersData
    .filter(user => user.username.toLowerCase().includes(searchedUsername.toLowerCase()))
    .sort((a, b) => (b.admin ? 1 : 0) - (a.admin ? 1 : 0));

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const moveForce = 10;
  const rotateForce = 10;

  useMouseMoveEffect(moveForce, rotateForce);

  const [userData, setUserData] = useState({
    username: '',
    admin: false,
    views: 0,
    created: '',
    posts: []
  });
  const [error, setError] = useState(null);
  const getCertainUser = async () => {
    const url = 'https://api.dxby.dev/dxbyn/user.php';
    const data = new FormData();
    data.append('id', id);

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: data,
      });

      if (response.ok) {
        const result = await response.json();
        if (result.error) {
          setError(result.error);
        } else {
          setUserData({
            username: result.username || '',
            admin: result.admin || false,
            views: result.views || 0,
            created: result.created || '',
            posts: result.posts || []
          });
        }
      } else {
        console.error('Error - Status:', response.status);
        setError('Error fetching user data');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    }
  };
  const getAllUsers = async () => {
    const url = 'https://api.dxby.dev/dxbyn/user.php';

    try {
      const response = await fetch(url);
      const data = await response.json();
      setUsersData(data.users);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (isCertainUser) getCertainUser();
    else getAllUsers();
  }, []);

  return (
    <div className='flex flex-col justify-center items-center w-screen'>
      {id && isCertainUser ? (
        <div id="showonuser" className="mt-10 moving-zone flex flex-col justify-center items-center">
          {error ? (
                <p className="custom-text-white text-2xl mt-5 custom-underline">{error}</p>
              ) : (
            <>
            <div className='backdrop-blur-sm fit-to-mobile group fade-in-right rounded-lg w-96 h-fit min-h-40 hover:backdrop-blur-md border-solid border-gray-800 border-2 pb-5 popup hover:shadow-xl shadow-md transition-shadow ease-in'>
                <div className='popup-content flex flex-col justify-center items-center'>
                  <h1 className={`text-white custom-text-white mt-5 text-3xl ${userData.admin ? 'admin' : ''}`}>{userData.username}</h1>
                  <h1 className='text-white font text-md mt-2'>Views: {userData.views}</h1>
                  <h1 className='text-gray-400 mt-3 font text-md'>Created: {userData.created}</h1>
                  <h1 className='text-gray-400 font mt-1 mb-2 text-sm'>{id}</h1>
                </div>
              </div>
              {userData.posts.length > 0 && (
                <div className="mt-5 flex flex-col justify-center items-center">
                  <h2 className="custom-text-white custom-underline text-2xl">User Posts:</h2>
                  <table  className='mobile-w-full blurbox w-full mt-5 text-white font'>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Views</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.posts.map(post => (
                        <tr key={post.id}>
                          <td>{post.id}</td>
                          <td className='custom-text-white-bold hover:text-blue-500 cursor-pointer hover:underline' onClick={() => window.location.href = `/dxbyn/post/${post.id}`}>{post.title}</td>
                          <td>{post.views}</td>
                          <td>{post.created}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
        
                </div>
              )}
                </>
          )}
        </div>
      ) : ( //! SHOW ALL USERS
        <div id="showonall" className="mt-10 flex flex-col w-full justify-center items-center">
          <h1 className='custom-text-white custom-underline text-3xl'>Users</h1>
          <input
            className='w-72 h-10 pl-3 font text-white pr-2 mt-5 bg-slate-900 outline-none rounded-md focus:-translate-y-1 hover:-translate-y-1 transition-all ease-in'
            autoComplete='off'
            placeholder='Search user'
            type="text"
            value={searchedUsername}
            onChange={(e) => setSearchedUsername(e.target.value)}
          />
          {currentUsers.length > 0 ? (
            <table className='blurbox mobile-w-full w-1/2 mt-5 text-white font'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Views</th>
                  <th>Joined</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map(user => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td
                      className={`username ${user.admin ? 'admin' : ''}`}
                      onClick={() => window.location.href = `/dxbyn/users/${user.id}`}
                    >
                      {user.username}
                    </td>
                    <td>{user.views}</td>
                    <td>{user.created}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="custom-text-white text-xl mt-5 custom-underline">No users found</p>
          )}

          <div className="mt-4 flex flex-row justify-center items-center font text-white">
            <button className="pagination-btn font" onClick={handlePrevPage} disabled={currentPage === 1}>
              Previous Page
            </button>
            <span className="mx-2 fade-in">{`Page ${currentPage} of ${totalPages}`}</span>
            <button className="pagination-btn font" onClick={handleNextPage} disabled={currentPage === totalPages}>
              Next Page
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
