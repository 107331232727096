import React, { useEffect } from 'react';

const UserHandler = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.dxby.dev/dxbyn/userhandler.php?token='+localStorage.getItem("token"));
        const data = await response.json();
        let isAdmin = localStorage.getItem("token");
        if(data.logout) {
            localStorage.removeItem("token");
        }else if(data.isBanned == "1" && !localStorage.getItem("banned") == "1") {
            localStorage.removeItem("token");
            localStorage.setItem("banned", data.isBanned)
            window.location.reload()
        }   
        else if(data.isAdmin) {
            localStorage.setItem("admin", data.isAdmin);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const intervalId = setInterval(fetchData, 2000);


    return () => clearInterval(intervalId);
  }, []);

  return (
    <div></div>
  );
};

export default UserHandler;
