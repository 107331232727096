import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from './pages/404';
import { SteinRun } from './SteinRun/SteinRun';
import { DxByn } from './dxbyn/dxbyn';
import { DxBynHome } from './dxbyn/home';
import { Signer } from './dxbyn/sign';
import { DxBynUsers } from './dxbyn/users';
import { DxBynAddPost } from './dxbyn/addpost';
import { DxBynPosts } from './dxbyn/posts';
import { DxBynReports } from './dxbyn/reports';
import Photos from './pages/Photos';
import { WhisperWrite } from './whisper/writeMessage';
import { WHome } from './whisper/home';
import { WSign } from './whisper/sign';
import { WhisperTOS } from './whisper/tos';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/steinrun" element={<SteinRun />} />
        <Route path="/dxbyn" element={<DxByn activeform={DxBynHome} />} />
        <Route path="/whisper/text/:code" element={<WhisperWrite/>} />
        <Route path="/whisper/tos" element={<WhisperTOS/>} />
        <Route path="/w/t/:code" element={<WhisperWrite/>} />
        <Route path="/whisper/" element={<WHome/>} />
        <Route path="/whisper/sign" element={<WSign/>} />
        <Route path="/whisper/sign/:code" element={<WSign/>} />
        <Route path="/whisper/*" element={<WHome/>} />
        <Route path="/photos" element={<Photos/>}/>
        <Route path="/dxbyn/sign" element={<DxByn activeform={Signer} />} />
        <Route path="/dxbyn/addpost" element={<DxByn activeform={DxBynAddPost} />} />
        <Route path="/dxbyn/users" element={<DxByn activeform={DxBynUsers} />} />
        <Route path="/dxbyn/users/:id/*" element={<DxByn activeform={(props) => <DxBynUsers {...props} />} />} />
        <Route path="/dxbyn/post/:id/*" element={<DxByn activeform={(props) => <DxBynPosts {...props} />} />} />
        <Route path="/dxbyn/reports/:id/*" element={<DxByn activeform={(props) => <DxBynReports {...props} />} />} />
        <Route path="/dxbyn/reports" element={<DxByn activeform={DxBynReports} />} />
        <Route path="/dxbyn/*" element={<DxByn activeform={NotFound} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
