import { useEffect } from 'react';

const useMouseMoveEffect = (moveForce, rotateForce) => {
  const handleMouseMove = (e) => {
    const docX = window.innerWidth;
    const docY = window.innerHeight;

    const popups = document.querySelectorAll('.popup');

    popups.forEach(popup => {
      const rect = popup.getBoundingClientRect();

      const moveX = (e.pageX - rect.left - rect.width / 2) / (docX / 2) * -moveForce;
      const moveY = (e.pageY - rect.top - rect.height / 2) / (docY / 2) * -moveForce;

      const rotateY = (e.pageX / docX) * rotateForce * 2 - rotateForce;
      const rotateX = -((e.pageY / docY) * rotateForce * 2 - rotateForce);

      popup.style.transform = `translate(${moveX}px, ${moveY}px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    });
  };

  useEffect(() => {
    const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    if (!isTouchDevice) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [moveForce, rotateForce]);
}

export default useMouseMoveEffect;