import React, { useEffect, useState } from "react";
import { FaUserFriends } from "react-icons/fa";
import { BsDiscord, BsTwitterX, BsGithub } from "react-icons/bs";
import { SiKofi } from "react-icons/si";
import { FaBitcoin, FaGamepad, FaNoteSticky, FaImage } from "react-icons/fa6";
import Particles from "./components/Particle";
import useMouseMoveEffect from "./js/useMousePopup";

import { HomeBTN, ContactBTN } from "./components/Buttons";
import Footer from "./components/Footer";

function App() {
	const [typedText, setTypedText] = useState("");
	const words = [
		"welcome! ",
		"I am Dxby...",
		"Dxby is just a fake name...",
		"this is my website! ",
		"I made this website!",
		"Please donate on Kofi! <3 ",
	];
	let isReversed = false;

	useEffect(() => {
		let wordIndex = 0;
		let letterIndex = 0;

		const intervalId = setInterval(() => {
			const currentWord = words[wordIndex];
			const currentLetter = currentWord && currentWord[letterIndex];

			if (isReversed) {
				setTypedText((prevTypedText) => prevTypedText.slice(0, -1));
			} else {
				setTypedText(
					(prevTypedText) => prevTypedText + (currentLetter || "")
				);
			}

			letterIndex += isReversed ? -1 : 1;

			if (isReversed && letterIndex === 0) {
				setTimeout(
					() => {
						isReversed = false;
						wordIndex = (wordIndex + 1) % words.length;
					},
					currentWord && currentWord.length === letterIndex ? 2000 : 0
				);
			} else if (
				!isReversed &&
				letterIndex === (currentWord && currentWord.length)
			) {
				isReversed = true;
			}
		}, 100);

		return () => clearInterval(intervalId);
	}, []);

	const moveForce = 10;
	const rotateForce = 10;

	useMouseMoveEffect(moveForce, rotateForce);

	return (
		<>
			<Particles />
			<div className="flex flex-col items-center justify-center fade-in">
				<div className="w-full flex flex-col items-center justify-center fixmf h-screen">
					<div className="flex flex-col items-center -mt-40 mobile-t w-full max-w-6xl ">
						<img
							alt="My Discord pfp"
							className="rounded-full outline-gray-800 outline-4 outline-none outline-offset-0 select-none w-24 z-10 pointer-events-none object-cover"
							src="https://dxby.dev/DISCORD.png"></img>
						<div className="blurbox fit-to-mobile select-none w-1/2 h-auto min-h-40 -translate-y-10 rounded-lg flex flex-col items-center justify-center">
							<h1 className="custom-text-white text-5xl mt-10 custom-underline hover:text-6xl transition-all duration-150 text-hover">
								DxBy
							</h1>
							<div className="socials fade-in">
								<Social
									icon={
										<BsTwitterX size="32" color="white" />
									}
									socialName="X"
									url="https://x.com/IamSTEINl"
								/>
								<Social
									icon={<BsDiscord size="32" color="white" />}
									socialName="Discord"
									url="https://discord.com/"
								/>
								<Social
									icon={<BsGithub size="32" color="white" />}
									socialName="Github"
									url="https://github.com/IamSTEINI"
								/>
								<Social
									icon={<SiKofi size="32" color="crimson" />}
									socialName="KoFi"
									url="https://ko-fi.com/dxbyte"
								/>
								<Social
									icon={
										<FaUserFriends
											size="32"
											color="white"
										/>
									}
									socialName="Rafa"
									url="https://xvcf.dev/"
								/>
							</div>
						</div>
						<div className="search-container -z-10">
							<span className="custom-text-white text-4xl text-search">
								Hey,{" "}
								<span className="custom-text-holed-white typed-text">
									{typedText}
								</span>
								<span
									className="typed-cursor typed-cursor--blink"
									aria-hidden="true"></span>
							</span>
						</div>
						<div className="flex flex-row mt-5 flex-wrap fade-in-right justify-center moving-zone">
							<div className="flex flex-col popup">
								<div
									onClick={() =>
										(window.location.href =
											"https://steinbank.dxby.dev")
									}
									className="popup-content blurbox hover:backdrop-blur-md w-72 mb-2 fade-in group select-none h-20 rounded-lg flex flex-row items-center justify-center hover:-translate-y-1 hover:shadow-lg cursor-pointer transition-all ease-in">
									<FaBitcoin
										className="rounded-xl mr-5 w-2/12 h-fit group-hover:w-1/5 transition-all ease-in"
										color="white"
									/>
									<h1 className="custom-text-white text-2xl custom-underline">
										Steinbank
									</h1>
								</div>
								<div
									onClick={() =>
										(window.location.href =
											"https://dxby.dev/photos")
									}
									className="popup-content blurbox hover:backdrop-blur-md w-72 mb-2 fade-in group select-none h-20 rounded-lg flex flex-row items-center justify-center hover:-translate-y-1 hover:shadow-lg cursor-pointer transition-all ease-in">
									<FaImage
										className="rounded-xl mr-5 w-2/12 h-fit group-hover:w-1/5 transition-all ease-in"
										color="white"
									/>
									<h1 className="custom-text-white text-2xl custom-underline">
										Photos
									</h1>
								</div>
								<div
									onClick={() =>
										(window.location.href =
											"https://dxby.dev/whisper")
									}
									className="popup-content blurbox hover:backdrop-blur-md w-72 mb-2 fade-in group select-none h-20 rounded-lg flex flex-row items-center justify-center hover:-translate-y-1 hover:shadow-lg cursor-pointer transition-all ease-in">
									<h5 className="rounded-xl mr-5 w-2/12 font-b text-5xl text-blue-500 h-fit group-hover:w-1/5 transition-all ease-in">
										W
									</h5>
									<h1 className="custom-text-white text-2xl custom-underline">
										Whisper
									</h1>
								</div>
								<div
									onClick={() =>
										window.open("https://dxby.dev/dxbyn")
									}
									className="popup-content blurbox hover:backdrop-blur-md w-72 mb-2 fade-in group select-none h-20 rounded-lg flex flex-row items-center justify-center hover:-translate-y-1 hover:shadow-lg cursor-pointer transition-all ease-in">
									<FaNoteSticky
										className="rounded-xl mr-5 w-2/12 h-fit group-hover:w-1/5 transition-all ease-in"
										color="white"
									/>
									<h1 className="custom-text-white text-2xl custom-underline">
										DxByn Forum
									</h1>
								</div>
							</div>

							<div className=" backdrop-blur-sm fit-to-mobile group abtmefix fade-in-right rounded-lg w-96 h-fit min-h-40 ml-10 hover:backdrop-blur-md border-solid border-gray-800 border-2 pb-5 popup hover:shadow-xl shadow-md transition-shadow ease-in">
								<div className="popup-content">
									<h1 className="custom-text-holed-white text-4xl transition-all ease-in duration-150 group-hover:-translate-y-1 custom-underline ml-5 mt-2">
										About me
									</h1>
									<p className="font text-white ml-5 mt-1 w-5/6 break-words">
										<bigp>Hallo, I'm Stein</bigp> I love web
										development, enjoying the creative
										process of designing and implementing
										functional, sleek websites. From
										front-end aesthetics to backend , I like
										to bringing ideas to life through coding
										and problem-solving.
									</p>
								</div>
							</div>
						</div>
						<div className="flex flex-row max-w-screen-sm flex-wrap mt-8">
							{/* <ContactBTN></ContactBTN> */}
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}
const Social = ({ icon, socialName = "Social", url = "#" }) => {
	const isMobile = window.innerWidth <= 1000;

	return (
		<div
			className="social group hover:shadow-lg"
			onClick={() => window.open(url)}>
			{icon}
			<span className={`tooltip group-hover:scale-100`}>
				{socialName}
			</span>
		</div>
	);
};

export default App;
