import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaEye,FaUserAlt } from "react-icons/fa";
import { MdDelete,MdReport } from "react-icons/md";
import { FaNoteSticky } from "react-icons/fa6";

export function DxBynPosts() {
  let { id } = useParams();
  id = /^[0-9]+$/.test(id) ? parseInt(id, 10) : '';
  const [error, setError] = useState(null);
  const [postData, setPostData] = useState([]);
  const [postValue, setPost] = useState('');
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const updatePost = async () => {
    const url = 'https://api.dxby.dev/dxbyn/post.php';
    const data = new FormData();
    data.append('token', localStorage.getItem("token"));
    data.append("newcontent", postValue)
    data.append("samepostid", id)
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: data,
      });

      if (response.ok) {
        const result = await response.json();
        if (result.postid) {
          window.location.href = "https://dxby.dev/dxbyn/post/"+result.postid;
        }
        if (result.error) {
          setError(result.error);
        } else {
          
        }
      } else {
        console.error('Error - Status:', response.status);
        setError('Error sending post, try again! Status: '+response.status);
      }
    } catch (error) {
      console.error('Error while posting:', error);
    }
  }
  const getPost = async () => {
    const url = `https://api.dxby.dev/dxbyn/post.php?id=${id}`;

    try {
      const response = await fetch(url);

      if (response.ok) {
        const result = await response.json();
        if (result.error) {
          setError(result.error);
        } else {
          setPostData(result.post);
          if(result.post.creatorid == localStorage.getItem("id")) {
            setIsCurrentUser(true)
            setPost(result.post.content)
          }
        }
      } else {
        console.error('Error - Status:', response.status);
        setError('Error fetching post data');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    }
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConfirmationD, setShowConfirmationD] = useState(false);
  const handleConfirm = async () => {
    setShowConfirmation(false);
    const url = `https://api.dxby.dev/dxbyn/report.php`;
    try {
      const data = new FormData();
      var token = localStorage.getItem("token");
      if (token) {
          data.append('token', token);
      }
      data.append("postid", id)
      const response = await fetch(url, {
        method: 'POST',
        body: data,
      });
      if (response.ok) {
        const result = await response.json();
        if (result.error) {
          setError(result.error);
        }
        if (result.reportid) {
          window.location.href = "/dxbyn/reports/"+result.reportid;
        }
      } else {
        console.error('Error - Status:', response.status);
        setError('Error fetching post data');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    }
  };
  const deletePost = async () => {
    const url = `https://api.dxby.dev/dxbyn/post.php`;
    try {
      const data = new FormData();
      var token = localStorage.getItem("token");
      if (token) {
          data.append('token', token);
      }
      data.append("postid", id)
      const response = await fetch(url, {method: "POST", body: data}); //? FIX Missing token or postid

      if (response.ok) {
        setShowConfirmationD(false);
        const result = await response.json();
        if (result.error) {
          setError(result.error);
        } else {
          window.location.href = "/dxbyn"
        }
      } else {
        console.error('Error - Status:', response.status);
        setError('Error fetching post data');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    }
  }
  const handleConfirmD = async () => {deletePost()}

  const handleCancel = () => {
    setShowConfirmation(false);
    setShowConfirmationD(false);
  };
  const reportPost = async () => {
    setShowConfirmation(true);
  };
  const showdelconf = async () => {
    setShowConfirmationD(true);
  }
  const confirmationClass = showConfirmation ? 'confirmation-show' : 'confirmation-hide';
  useEffect(() => {
    getPost();
  }, []);

  return (
    <div className="flex flex-col items-center">
      {showConfirmation && (
        <div className={`absolute bg-gray-800 shadow-2xl z-50 flex flex-col rounded-lg mt-10`}>
          <p className='custom-text-white text-xl p-5'>Are you sure you want to report this post?</p>
          <div className='flex flex-row gap-5 w-full'>
          <button className='w-1/2 h-10 rounded-md bg-green-600 hover:bg-green-700 text-white font' onClick={handleConfirm}>Yes</button>
          <button className='w-1/2 h-10 rounded-md bg-red-600 hover:bg-red-700 text-white font' onClick={handleCancel}>No</button>
          </div>
        </div>
      )}
      {showConfirmationD && (
        <div className={`absolute bg-gray-800 shadow-2xl z-50 flex flex-col rounded-lg mt-10`}>
          <p className='custom-text-white text-xl p-5'>Are you sure you want to delete your post?</p>
          <div className='flex flex-row gap-5 w-full'>
          <button className='w-1/2 h-10 rounded-md bg-green-600 hover:bg-green-700 text-white font' onClick={handleConfirmD}>Yes</button>
          <button className='w-1/2 h-10 rounded-md bg-red-600 hover:bg-red-700 text-white font' onClick={handleCancel}>No</button>
          </div>
        </div>
      )}
      {error ? (
        <p className="font text-red-500 text-xl mt-5">{error}</p>
      ) : (
           <div className='w-screen h-full flex flex-col'>
           <div className='flex flex-row flex-wrap justify-start items-center mt-5'>
            <h1 title='Title' className='custom-text-white-bold text-3xl ml-4 mobile-center'>{postData.title}</h1>
            <div title='Author' className='flex ml-4 group flex-row cursor-pointer justify-center items-center mobile-center' onClick={() => window.location.href = `/dxbyn/users/${postData.creatorid}`}>            
                <FaUserAlt size={20} className='opacity-50' color='white'/>
                <h1 className='font group-hover:underline text-xl ml-2 text-gray-400'>{postData.creatorname} {isCurrentUser && "(YOU)"}</h1>
            </div>
            <div title='Views' className='flex ml-4 flex-row justify-center items-center mobile-center'>            
                <FaEye size={20} color='white'/>
                <h1 className='font text-lg ml-2 text-white'>{postData.views}</h1>
            </div>
           </div>
           <div className='w-screen h-screen flex flex-col outline-none border-none'>
             <div className='h-4/5 mt-3 mb-1 mr-3 ml-3 rounded-lg outline-none border-none bg-gray-950 flex flex-col justify-center items-center'>
              {isCurrentUser ? (
                  <textarea value={postValue} style={{ lineHeight: "19px" }} onChange={(e) => setPost(e.target.value)} name="content" autoCorrect='off' wrap='off' autoComplete='off' className='outline-none border-none bg-transparent text-white font-textbox cursor-text text-base w-full h-full mobile-font-size resize-none p-3' rows="4" cols="500" placeholder="Write your post...BUT READ THE TERMS OF SERVICE (https://dxby.dev/dxbyn/post/1/)"></textarea>
              ): (
                <pre name="content" autoCorrect='off' style={{ lineHeight: "17px" }} wrap='off' autoComplete='off' className='outline-none mobile-font-size border-none bg-transparent text-white font-textbox text-sm overflow-auto w-full h-full resize-none p-3' rows="4" cols="500" placeholder="Write your post...BUT READ THE TERMS OF SERVICE (https://dxby.dev/dxbyn/post/1/)">
                {postData.content}
                </pre>
              )}
             </div>
             <div className='flex flex-row flex-wrap justify-start items-center mt-3 ml-3 gap-1'>
              <div onClick={reportPost} className='bg-red-600 flex border flex-row justify-start items-center pl-5 pr-5 pt-2 pb-2 fade-in select-none rounded-lg hover:-translate-y-1 hover:shadow-lg hover:bg-red-500 cursor-pointer transition-all ease-in'>
                  <MdReport color='white' size={20}/>
                  <h1 className='font ml-2 text-white text-lg'>Report</h1> 
              </div>
              {isCurrentUser && (
                <div className='flex flex-row gap-1'>
                  <div onClick={showdelconf} className='bg-red-600 flex border flex-row justify-start items-center pl-5 pr-5 pt-2 pb-2 fade-in select-none rounded-lg hover:-translate-y-1 hover:shadow-lg hover:bg-red-500 cursor-pointer transition-all ease-in'>
                    <MdDelete color='white' size={20}/>
                    <h1 className='font ml-2 text-white text-lg'>Delete</h1>
                  </div>
                  <div onClick={updatePost} className='bg-green-600 flex border flex-row justify-start items-center pl-5 pr-5 pt-2 pb-2 fade-in select-none rounded-lg hover:-translate-y-1 hover:shadow-lg hover:bg-green-500 cursor-pointer transition-all ease-in'>
                    <FaNoteSticky color='white' size={20}/>
                    <h1 className='font ml-2 text-white text-lg'>Apply edit</h1>
                  </div>
                </div>
              )}
          </div>
           </div>
           
         </div>
      )}
    </div>
  );
}
