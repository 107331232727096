import React, { useState, useEffect } from "react";
import { IoIosCopy } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import "../index.scss";
import "./index.css";

export function WHome() {
	const [copied, setCopied] = useState(false);
	const [copiedL, setCopiedL] = useState(false);
	
	const [showDiv, setShowDiv] = useState(false);
    const [username, setUsername] = useState("");
	const [error, setError] = useState(null);
    const [msgError, setMsgError] = useState(null);
    const [codeToCopy, setCodeToCopy] = useState("");
	const [linkToCopy, setLinkToCopy] = useState("");
	
	const [isError, setIsError] = useState(false);
    const [messages, setMessages] = useState([]);

	const navigate = useNavigate();

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				setCopied(true);
				setTimeout(() => setCopied(false), 2000);
			})
			.catch((err) => {
				console.error("Failed to copy to clipboard:", err);
			});
	};
	const copyLToClipboard = (text) => {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					setCopiedL(true);
					setTimeout(() => setCopiedL(false), 2000);
				})
				.catch((err) => {
					console.error("Failed to copy to clipboard:", err);
				});
		};
	useEffect(() => {
		const history = window.history;
		history.pushState({}, "", "/whisper/");
		if (!localStorage.getItem("Wtoken")) {
			window.location.href = "/whisper/sign"
		}
		  
	}, []);

	const handleInputChange = (event) => {
		const inputValue = event.target.value;
		setShowDiv(inputValue.length === 6);
	};
	const getUserByToken = async () => {
		const url = `https://api.dxby.dev/whisper/getUserByToken.php`;
		try {
			const data = new FormData();
			var token = localStorage.getItem("Wtoken");
			if (token) {
				data.append("token", token);
			}
			data.append("token", token);
			const response = await fetch(url, { method: "POST", body: data });

			if (response.ok) {
				const result = await response.json();
				console.log(result);
				if (result.error) {
					setError(result.error);
					setIsError(true);
				} else {
					setUsername(result.username);
                    setCodeToCopy(result.code);
					setLinkToCopy("https://dxby.dev/w/t/"+result.code);
					setIsError(false);
                    
				}
			} else {
				console.error("Error - Status:", response.status);
				setError("Error fetching data");
			}
		} catch (error) {
			console.error("Error while fetching data:", error);
		}
	};
    const getMessages = async () => {
            const url = `https://api.dxby.dev/whisper/getMessages.php`;
            try {
                const data = new FormData();
                var token = localStorage.getItem("Wtoken");
                if (token) {
                    data.append("token", token);
                }
                data.append("token", token);
                const response = await fetch(url, { method: "POST", body: data });
    
                if (response.ok) {
                    const result = await response.json();
                    console.log(result);
                    if (result.error) {
                        setError(result.error);
                        setIsError(true);
                    }else if(result.msgerror) {
                        setMsgError(result.msgerror)
                    }
                     else {
                        setMessages(result.messages)
						setIsError(false)
						setMsgError(null)
                    }
                } else {
                    console.error("Error - Status:", response.status);
                    setError("Error fetching data");
                }
            } catch (error) {
                console.error("Error while fetching data:", error);
            }
        };
	useEffect(() => {
		getUserByToken();
        getMessages();
	}, []);
	const resetT = () => {
		localStorage.removeItem("Wtoken");
		window.location.reload()
	}
	const handleWriteTextClick = () => {
		const enteredCode = document.getElementById("codeInput").value;
		navigate(`text/${enteredCode}`);
	};

	return (
		<div className="w-screen h-screen flex flex-col items-center whisper-html">
            {isError ? (
                    <div className="text-center mt-5 w-full h-full flex flex-col justify-center items-center">
                      {error}
                      <div className="btn" onClick={() => getUserByToken()}>Retry</div>
                      <div className="btn" onClick={() => resetT()}>Reset token</div>
                    </div>
                  ) : (
			<div className="w-screen h-max flex flex-col items-center whisper-html">
				<img className="mt-10" src="https://dxby.dev/WHISPER.svg"></img>
				<div className="flex mt-10 flex-row gap-2">
					<h1 className="text-2xl">Hey, </h1>
					<p className="cfont-bold text-black text-2xl">{username}</p>
				</div>
				<div
					id="copyCode"
					className="flex flex-col justify-center items-center cursor-pointer"
					onClick={() => copyToClipboard(codeToCopy)}>
					<div className="flex-row flex group border-black rounded-md mt-5 border-2 hover:border-opacity-50 border-solid p-2 justify-center items-center">
						<p
							className={`text-2xl mr-1 cfont-black ${
								copied ? "text-green-500" : "text-black"
							}`}>
							{codeToCopy}
						</p>
						<IoIosCopy
							size={16}
							color="black"
							className={`mt-0.5 group-hover:opacity-100 opacity-50`}
						/>
					</div>
				</div>
				<div
					id="copyLink"
					className="flex flex-col justify-center items-center cursor-pointer"
					onClick={() => copyLToClipboard(linkToCopy)}>
					<div className="flex-row flex group border-black rounded-md mt-1 border-2 hover:border-opacity-50 border-solid p-2 justify-center items-center">
						<p
							className={`text-xl mr-1 font-bold ${
								copiedL ? "text-green-500" : "text-black"
							}`}>
							{linkToCopy}
						</p>
						<IoIosCopy
							size={16}
							color="black"
							className={`mt-0.5 group-hover:opacity-100 opacity-50`}
						/>
					</div>
				</div>
				<input
					id="codeInput"
					className="border-black rounded-md mt-3 text-xl border-2 w-48 text-center hover:border-opacity-50 border-solid outline-none p-2 font-bold"
					maxLength={6}
					placeholder="Enter code"
					autoComplete="off"
					max={6}
					onChange={handleInputChange}
				/>
				{showDiv && (
					<div
						id="showWhenInputFull"
						className="btn"
						onClick={handleWriteTextClick}>
						Write Text
					</div>
				)}
				<div className="flex flex-col w-screen justify-center items-center">
					<div
					className="btn"
					onClick={resetT}>
					Logout
					</div>
                    {msgError && (
                          <div className="flex flex-col w-screen justify-center items-center">
                            <div className="text-center mt-5">
                              {msgError}
                              <div className="btn" onClick={getMessages}>Retry</div>
                            </div>
                          </div>
                        )}
                        {messages.length > 0 && (
                          <div className="flex flex-col w-screen justify-center items-center">
							<div className="btn" onClick={getMessages}>Reload</div>
                            {messages.map((message) => (
								
                              <Message key={message.id || Math.random()} message={message.message} />
                            ))}
                          </div>
                        )}
				</div>

			</div>
            )}
		</div>
	);
}
//TODO: Passwort reset
const Message = ({ message }) => {
	return (
		<div className="w-96 h-fit flex flex-row mt-2 cursor-pointer border-black border-solid border-2 p-2 rounded-md drop-shadow-md hover:shadow-xl transition-all ease-in items-start justify-center bg-white">
			<FaUser size={32} color="#00B2FF" className="mt-0.5" />
			<div className="ml-1 w-80 overflow-hidden break-words p-2 hyphens-auto text-xl flex flex-row justify-start items-center">
				{message}
			</div>
		</div>
	);
};
