import React, { useState, useEffect } from 'react';
import { AdminCheck } from './components/adminCheck';
import { useParams } from 'react-router-dom';
import { MdDelete } from "react-icons/md";
import { FaEnvelopeOpenText,FaHammer,FaMinusCircle } from "react-icons/fa";
export function DxBynReports() {
  let { id } = useParams();
  id = /^[0-9]+$/.test(id) ? parseInt(id, 10) : '';
  const isCertainReport = /^[0-9]+$/.test(id);
  const [error, setError] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 25;
  const [searchedReporterName, setSearchedReporterName] = useState('');
  const getReport = async () => {
    const url = `https://api.dxby.dev/dxbyn/report.php?reportid=${id}`;

    try {
      const response = await fetch(url);

      if (response.ok) {
        const result = await response.json();
        if (result.error) {
          setError(result.error);
        } else {
          setReportData(result.report);
          setPostData(result.report.post[0]);
        }
      } else {
        console.error('Error - Status:', response.status);
        setError('Error fetching post data');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    }
  };
  const getAllReports = async () => {
    const url = `https://api.dxby.dev/dxbyn/report.php`;
    try {
      const response = await fetch(url);

      if (response.ok) {
        const result = await response.json();
        if (result.error) {
          setError(result.error);
        } else {
          setReportData(result.reports);
        }
      } else {
        console.error('Error - Status:', response.status);
        setError('Error fetching post data');
      }
    } catch (error) {
      console.error('Error while fetching data:', error);
    }
  };
  const filteredReports = searchedReporterName.length > 1
    ? reportData.filter(report => report.reportername.toLowerCase().includes(searchedReporterName.toLowerCase()))
    : reportData;
  
const totalPages = Math.ceil(filteredReports.length / reportsPerPage);

  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = searchedReporterName.length > 1 ? filteredReports.slice(indexOfFirstReport, indexOfLastReport) : filteredReports;
  
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  const banUser = (userId, reportid) => {
    if(prompt("TYPE 'yes' TO BAN "+userId) == "yes") {
      const form = new FormData();
      form.append("token", localStorage.getItem("token")); 
      form.append("targetid", userId);
    
      const options = {
        method: 'POST',
        body: form,
      };
    
      fetch('https://api.dxby.dev/dxbyn/banUser.php', options)
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            removeReport(reportid)
          } else {
            alert(response.error)
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };
  const removeReport = (reportId) => {
    // USE TOKEN => ADMIN
    const token = localStorage.getItem("token");
    const form = new FormData();
    form.append("token", token); 
    form.append("reportid", reportId);
    fetch(`https://api.dxby.dev/dxbyn/report.php`, {
      method: 'POST',
      body: form,
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          const reportElement = document.getElementById("report_" + reportId);
          if (reportElement) {
            reportElement.remove();
          }
        } else {
          alert(response.error);
        }
      })
      .catch(error => console.error(error));
  };
  const removePost = (postId,reportid) => {
    //! USE TOKEN => ADMIN
    if(prompt("TYPE 'yes' TO DELETE "+postId) == "yes") {
      const form = new FormData();
      form.append("token", localStorage.getItem("token")); 
      form.append("postid", postId);
      fetch('https://api.dxby.dev/dxbyn/post.php', {
        method: 'POST',
        body: form,
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            removeReport(reportid)
          } else {
            alert(response.error);
          }
        })
        .catch(error => console.error(error));
    }
    
  };
  useEffect(() => {
    if (isCertainReport) getReport();
    else getAllReports();
  }, []);
  return (
    <>
    <div className='flex flex-col justify-center items-center'>
          {error ? (
              <p className="font text-red-500 text-xl mt-5">{error}</p>
          ) : ( id && isCertainReport ? (
                  <div className='flex flex-col mt-5 justify-center items-center'>
                      <h1 className='custom-text-white custom-underline mt-5 text-3xl'>Report</h1>
                      <p className='font text-white opacity-50 mt-3'>{reportData.reportid}</p>
                      <p className='font text-white opacity-50 mt-3'>Reported by: <a className="username" onClick={() => window.location.href = `/dxbyn/users/${reportData.reporterid}`}>{reportData.reportername}</a></p>
                      <h1 className='font custom-text-white mt-3 text-xl'>
                          Reported post:
                      </h1>
                      <a className='text-white hover:text-blue-500 hover:underline text-2xl' href={`/dxbyn/post/${reportData?.postid}`}>
                          {postData.title}
                      </a>
                      <p className='font text-white opacity-50 mt-3'>Your report will be reviewed by an administrator soon!</p>
                  </div>
              ) : (
                 <><AdminCheck />
                  <div id="showonall" className="mt-10 flex flex-col w-full justify-center items-center">
                    <h1 className='custom-text-white custom-underline text-3xl'>Reports</h1>
                    <input
                      className='w-72 h-10 pl-3 font text-white pr-2 mt-5 bg-slate-900 outline-none rounded-md focus:-translate-y-1 hover:-translate-y-1 transition-all ease-in'
                      autoComplete='off'
                      placeholder='Search reporter name'
                      type="text"
                      value={searchedReporterName}
                      onChange={(e) => setSearchedReporterName(e.target.value)} />
                  {currentReports.length > 0 ? (
                    <table className='blurbox w-3/4 mobile-w-full mt-5 text-white font'>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Reporter (Name)</th>
                          <th>Post (Name)</th>
                          <th>Created</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentReports.map(report => (
                          <tr key={report.id} id={`report_${report.id}`}>
                            <td>{report.id}</td>
                            <td
                              className={`username`}
                              onClick={() => window.location.href = `/dxbyn/users/${report.reporterid}`}
                            >
                              {report.reportername}
                            </td>
                            <td className='username' onClick={() => window.location.href = `/dxbyn/post/${report.postid}`}>{report.post[0].title}</td>
                            <td>{report.created}</td>
                            <div className='flex flex-row flex-wrap'>
                              <td>
                                <div title='Open report'
                                  onClick={() => window.location.href = `/dxbyn/reports/${report.id}`}
                                  className='p-2 flex flex-row justify-center w-fit items-center rounded-md active:-translate-y-0 active:bg-green-800 bg-green-800 hover:bg-green-500 hover:cursor-pointer hover:-translate-y-0.5 hover:shadow-sm transition-all ease-in'>
                                  <FaEnvelopeOpenText style={40} color='white' />
                                </div>
                              </td>
                              <td>
                                <div title='Ban user'
                                  onClick={() => banUser(report.reportedid,report.id)}
                                  className='p-2 flex flex-row justify-center w-fit items-center rounded-md active:-translate-y-0 active:bg-red-800 bg-red-800 hover:bg-red-500 hover:cursor-pointer hover:-translate-y-0.5 hover:shadow-sm transition-all ease-in'>
                                  <FaHammer style={40} color='white' />
                                </div>
                              </td>
                              <td>
                                <div title='Remove report'
                                  onClick={() => removeReport(report.id)}
                                  className='p-2 flex flex-row justify-center w-fit items-center rounded-md active:-translate-y-0 active:bg-orange-800 bg-orange-800 hover:bg-orange-500 hover:cursor-pointer hover:-translate-y-0.5 hover:shadow-sm transition-all ease-in'>
                                  <FaMinusCircle style={40} color='white' />
                                </div>
                              </td>
                              <td>
                                <div title='Remove post'
                                  onClick={() => removePost(report.post[0].id,report.id)}
                                  className='p-2 flex flex-row justify-center w-fit items-center rounded-md active:-translate-y-0 active:bg-violet-700 bg-violet-700 hover:bg-violet-500 hover:cursor-pointer hover:-translate-y-0.5 hover:shadow-sm transition-all ease-in'>
                                  <MdDelete style={40} color='white' />
                                </div>
                              </td>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="custom-text-white text-xl mt-5 custom-underline">No reports found</p>
                  )}
                  <div className="mt-4 flex flex-row justify-center items-center font text-white">
                    <button className="pagination-btn font" onClick={handlePrevPage} disabled={currentPage === 1}>
                      Previous Page
                    </button>
                    <span className="mx-2 fade-in">{`Page ${currentPage} of ${totalPages}`}</span>
                    <button className="pagination-btn font" onClick={handleNextPage} disabled={currentPage === totalPages}>
                      Next Page
                    </button>
                  </div>
                </div>
              </>
              )
          )}
      </div>
    </>
  );
}