import { Signer } from './sign';
import React, { useEffect, useState } from 'react';
import Particles from '../components/Particle';
import { Navbar } from './components/NavBar';
import UserHandler from '../components/UserHandler';
export function DxByn({ activeform: ActiveForm }) {
    const [isLoginVisible, setLoginVisible] = useState(false);

    const showLogin = () => {
        setLoginVisible(true);
    };

    const hideLogin = () => {
        setLoginVisible(false);
    };
    //! CREATE POSTS, ACCOUNT ONLY WITH TOKEN!!!!! VIEWING STUFF IS OKAY WITHOUT
    return (
        <>
        <Particles />

        <div>
            <Navbar />
            <ActiveForm/>
        </div>
        <UserHandler/>
        </>
    );
}
