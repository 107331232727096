import React, { useState, useEffect } from "react";
import { IoIosCopy } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import "../index.scss";
import "./index.css";

export function WSign() {
    const { code } = useParams();
    const [isLoginActive, setIsLoginActive] = useState(true);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [isError, setIsError] = useState(false);
  

  const handleToggle = () => {
    setIsLoginActive(!isLoginActive);
  };
    const submitLogin = async () => {
    const url = `https://api.dxby.dev/whisper/login.php`;
    try {
        const data = new FormData();
        data.append("username", username)
        data.append("password", password)
    
        const response = await fetch(url, {method: "POST", body: data});

        if (response.ok) {
            const result = await response.json();
            console.log(result)
            if (result.error) {
                setError(result.error);
            } else {
                if (result && result.token) {
                    localStorage.setItem("Wtoken", result.token);
                    if(code != undefined) {
                      window.location.href = "/w/t/"+code
                    }else {
                      window.location.href = "/whisper/"
                    }
                }
            }
        } else {
            console.error('Error - Status:', response.status);
            setError('Error fetching data');
        }
    } catch (error) {
    console.error('Error while fetching data:', error);
    } 
  }
  useEffect(() => {
        if (localStorage.getItem("Wtoken")) {
            window.location.href = "/whisper/"
        }
          
    }, []);
  const submitRegister = async () => {
      const url = `https://api.dxby.dev/whisper/register.php`;
      try {
          const data = new FormData();
          data.append("username", username)
          data.append("email", email)
          data.append("password", password)
          const response = await fetch(url, {method: "POST", body: data});
          if (response.ok) {
              const result = await response.json();
              console.log(result)
              if (result.error) {
                  setError(result.error);
              } else {
                  if (result && result.token) {
                      localStorage.setItem("Wtoken", result.token);
                      if(code != undefined) {
                        window.location.href = "/w/t/"+code
                      }else {
                        window.location.href = "/whisper/"
                      }
                  }
              }
          } else {
              console.error('Error - Status:', response.status);
              setError('Error fetching data');
          }
      } catch (error) {
      console.error('Error while fetching data:', error);
      } 
    }

    const openTos = () => {
      window.open("/whisper/tos")
    }

  return (
    <div className="w-screen h-screen flex flex-col items-center whisper-html">
      {isError ? (
        <div className="text-center mt-5">
        </div>
      ) : (
        <>
          <img className="mt-10" src="https://dxby.dev/WHISPER.svg" />
          <div className="flex flex-row gap-5">
            <h1 onClick={handleToggle} className={`mt-10 text-2xl cursor-pointer hover:cfont-bold ${isLoginActive ? "text-blue-500 cfont-bold" : ""}`}>
              Login
            </h1>
            <h1 onClick={handleToggle} className={`mt-10 text-2xl cursor-pointer hover:cfont-bold ${!isLoginActive ? "text-blue-500 cfont-bold" : ""}`}>
              Register
            </h1>
          </div>
            <h1 className="text-red-500 text-xl mt-1 cfont-bold text-center w-max">{error}</h1>
            
          {isLoginActive ? (
            <div className="login flex flex-col gap-1 justify-center items-center">
              <input
                id="usernameInput"
                onChange={() => setUsername(document.getElementById('usernameInput').value)}
                type="text"
                autoComplete="off"
                className="border-black rounded-md mt-3 text-xl border-2 w-60 hover:border-opacity-50 border-solid outline-none p-2 cfont-bold"
                placeholder="Username"
              />
              <input
                id="passwortInput"
                onChange={() => setPassword(document.getElementById('passwortInput').value)}
                type="password"
                autoComplete="off"
                className="border-black rounded-md mt-3 text-xl border-2 w-60 hover:border-opacity-50 border-solid outline-none p-2 cfont-bold"
                placeholder="Password"
              />
              <h1 className="text-sm text-gray-700 font translate-y-2">Everything is encrypted in our DB.</h1>
              <div className="btn" onClick={submitLogin}>
                Login
              </div>
            </div>
          ) : (
            <div className="register flex flex-col gap-1 justify-center items-center">
              <input
                id="usernameInput"
                onChange={() => setUsername(document.getElementById('usernameInput').value)}
                type="text"
                autoComplete="off"
                className="border-black rounded-md mt-3 text-xl border-2 w-60 hover:border-opacity-50 border-solid outline-none p-2 cfont-bold"
                placeholder="Username"
              />
              <input
                id="emailInput"
                onChange={() => setEmail(document.getElementById('emailInput').value)}
                type="email"
                autoComplete="off"
                className="border-black rounded-md mt-3 text-xl border-2 w-60 hover:border-opacity-50 border-solid outline-none p-2 cfont-bold"
                placeholder="Email"
              />
              <input
                id="passwortInput"
                onChange={() => setPassword(document.getElementById('passwortInput').value)}
                type="password"
                autoComplete="off"
                className="border-black rounded-md mt-3 text-xl border-2 w-60 hover:border-opacity-50 border-solid outline-none p-2 cfont-bold"
                placeholder="Password"
              />
              <div className="flex flex-row">
              <h1 className="text-sm text-gray-700 font translate-y-2">If you register, you automaticly agree to our.</h1>
              <h1 onClick={() => openTos()} className="text-sm text-gray-700 translate-y-2 cfont-bold ml-1 cursor-pointer hover:underline">TOS</h1>
              </div>
              <h1 className="text-sm text-gray-700 font translate-y-2">Everything is encrypted in our DB.</h1>
              <div className="btn" onClick={submitRegister}>
                Register
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );}