import React, { useEffect, useState } from 'react';
import { MdOutlineMenu,MdPushPin } from "react-icons/md";
import "../index.css"
export function Navbar() {
    const token = localStorage.getItem("token");
    const [showMenu, setShowMenu] = useState(false);
    const handleLogout = () => {
      localStorage.removeItem("token");
      window.location.href="/dxbyn"
    };
    const isAdmin = localStorage.getItem('admin') === '1';
    const tshowMenu = () => {
      setShowMenu(!showMenu)
    }
    return (
      <>
      <p className='font text-white opacity-25 text-sm z-50 fixed bottom-5 w-full text-center pointer-events-none hide-on-mobile'>© 2024 - All rights reserved | Made by Stein</p>
      <div className='w-full min-h-12 h-fit z-50 bg-gray-950 flex flex-row'>
        <div className={`flex flex-${showMenu === true ? 'col' : 'row'} flex-wrap w-3/4 justify-start items-center z-0 select-none`}>
          <h1 onClick={() => window.location.href = "/dxbyn"} className={`custom-text-white cursor-pointer ml-10 text-2xl select-none ${showMenu === true ? 'hidden' : 'block'}`}>DxByn</h1><br></br>
          <div className={`flex flex-row ml-5 mobile-menu-hide justify-start gap-5 items-center z-0 select-none`}>
            <h1 onClick={() => window.location.href = "/dxbyn"} className='text-white font text-md cursor-pointer hover:text-blue-400'>Home</h1>
            <h1 onClick={() => window.location.href = "/dxbyn/post/0/"} className='text-white font text-md cursor-pointer hover:text-blue-400'>Privacy</h1>
            <h1 onClick={() => window.location.href = "/dxbyn/post/1/"} className='text-white font text-md cursor-pointer hover:text-blue-400'>Terms</h1>
            <h1 onClick={() => window.location.href = "/dxbyn/users"} className='text-white font text-md cursor-pointer hover:text-blue-400'>Users</h1>
            <h1 onClick={() => window.location.href = "/dxbyn/addpost"} className='text-white font text-md cursor-pointer hover:text-blue-400'>Add Post</h1>
            <h1 onClick={() => window.location.href = "/dxbyn/reports"} className={`text-red-600 font text-md cursor-pointer hover:text-red-400 ${isAdmin ? '' : 'hidden'}`}>Reports</h1>
          </div>
          {/* {showMenu && (
            <div className={`flex flex-col justify-start gap-1 pb-2 items-center z-0 select-none`}>
              <h1 onClick={() => (window.location.href = "/dxbyn")} className='text-white font text-md cursor-pointer hover:text-blue-400'>Home</h1>
              <h1 onClick={() => (window.location.href = "/dxbyn/post/0/")} className='text-white font text-md cursor-pointer hover:text-blue-400'>Privacy</h1>
              <h1 onClick={() => (window.location.href = "/dxbyn/post/1/")} className='text-white font text-md cursor-pointer hover:text-blue-400'>Terms</h1>
              <h1 onClick={() => (window.location.href = "/dxbyn/users")} className='text-white font text-md cursor-pointer hover:text-blue-400'>Users</h1>
              <h1 onClick={() => (window.location.href = "/dxbyn/addpost")} className='text-white font text-md cursor-pointer hover:text-blue-400'>Add Post</h1>
              <h1 onClick={() => (window.location.href = "/dxbyn/reports")} className={`text-red-600 font text-md cursor-pointer hover:text-red-400 ${isAdmin ? '' : 'hidden'}`}>Reports</h1>
            </div>
          )} */}

        </div>
        <div className='flex flex-row w-4/12 mr-2 justify-end gap-5 items-center bg-gray-950 z-10'>
          {/* <div onClick={tshowMenu} className={`flex flex-col justify-${showMenu === true ? 'start' : 'center'} items-${showMenu === true ? 'start' : 'center'} mobile-menu-show w-fit h-fit pr-2 pl-2 pt-0.5 pb-0.5 border rounded-md cursor-pointer border-solid border-black bg-slate-900 `}>
            <MdOutlineMenu color='white' size={30}/>
          </div> */}
          {!token && (
            <h1 onClick={() => window.location.href = "/dxbyn/sign"} className={`text-white font text-md cursor-pointer hover:text-blue-400 mfixreg ${showMenu === true ? 'hidden' : 'block'}`}>Login / Register</h1>
          )}

          {token && (
            <>
              <h1 className='text-white font text-md cursor-pointer hover:text-blue-400' onClick={handleLogout}>Logout</h1>
            </>
          )}
        </div>
      </div><br></br><br></br></>
    );
  }